import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Informacion.css'
import Plantilla from '../plantilla/Plantilla'
import Plantilla2 from '../plantilla/Plantilla2'
import Plantilla3 from '../plantilla/Plantilla3'
import Plantilla4 from '../plantilla/Plantilla4'
import Plantilla5 from '../plantilla/Plantilla5'
import Plantilla6 from '../plantilla/Plantilla6'

const Informacion = (props) => {

    const [informacion, setInformacion] = useState(0)
    const [mostrarInformacion, setMostrarInformacion] = useState(0)

    useEffect( () => {
        const apiUrl =  'https://mantenedor-informacion.tabsa.cl/api/info'

        const fetchData = async () => {
            const result = await axios(apiUrl)
            const data = await result.data 
            console.log(data)
            setInformacion(data)
        }
        fetchData()
    }, [])

	useEffect( () => {
		if (informacion) {
			setMostrarInformacion(true)
		}
	}, [informacion])

    // switch (informacion.data.plantilla) {
    //     case 1:
    //         <Plantilla></Plantilla>
    //       break;
    //     case 2:
    //         <Plantilla2></Plantilla2>
    //       break;
    //     case 3:
    //         <Plantilla3></Plantilla3>
    //       break;
    //     case 4:
    //         <Plantilla4></Plantilla4>
    //       break;
    //     case 5:
    //         <Plantilla5></Plantilla5>
    //       break;
    //     case 6:
    //         <Plantilla6></Plantilla6>
    //       break;
    // }

    return (
        informacion
        ?   <body>
        <div className="wrapper">

        {informacion.data.plantilla == 0 ?
        <div>
            {informacion.data[0].id_tipo_contenido == 1 ? 
            <div>
                <div class="u_t">
                    <b className="textoTitulo">{informacion.data[0].titulo}</b>
                </div> 

                <div class="u_c_t">
                    <p className='g_txt'>{informacion.data[0].contenido}</p>
                </div>
            </div>
            : 
            <div class="u_img">
                <img className="image" src={informacion.data[0].contenido}/>
            </div>
            }
        </div>
        :<></>}

        {informacion.data.plantilla == 2 ?  
        <div>
            <div className='dl_cu'>
            {informacion.data[0].id_tipo_contenido == 1 ? 
            <div>
                <div class="cu_t">
                    <b className="textoTitulo">{informacion.data[0].titulo}</b>
                </div> 

                <div class="cu_c_t">
                    <p className='g_txt'>{informacion.data[0].contenido}</p>
                </div>
            </div>
            : 
            <div class="cu_img">
                <img className="image" src={informacion.data[0].contenido}/>
            </div>
            }
            </div>
            {informacion.data[1].id_tipo_contenido == 1 ? 
            <div>
                <div class="d_t">
                    <b className="textoTitulo">{informacion.data[1].titulo}</b>
                </div> 

                <div class="d_c_t">
                    <p className='g_txt'>{informacion.data[1].contenido}</p>
                </div>
            </div>
            : 
            <div class="d_img">
                <img className="image" src={informacion.data[1].contenido}/>
            </div>
            }
        </div>
        
        :<></>}

        {informacion.data.plantilla == 3 ?  
        <div>
            {informacion.data[0].id_tipo_contenido == 1 ? 
            <div>
                <div class="t_t">
                    <b className="textoTitulo">{informacion.data[0].titulo}</b>
                </div> 

                <div class="t_c_t">
                    <p className='g_txt'>{informacion.data[0].contenido}</p>
                </div>
            </div>
            : 
            <div class="t_img">
                <img className="image" src={informacion.data[0].contenido}/>
            </div>
            }
            {informacion.data[1].id_tipo_contenido == 1 ? 
            <div>
                <div class="t_t">
                    <b className="textoTitulo">{informacion.data[1].titulo}</b>
                </div> 

                <div class="t_c_t">
                    <p className='g_txt'>{informacion.data[1].contenido}</p>
                </div>
            </div>
            : 
            <div class="t_img">
                <img className="image" src={informacion.data[1].contenido}/>
            </div>
            }
        </div>
        
        :<></>}

        {informacion.data.plantilla == 4 ?  
        <div>
            <div className='dl_cu'>
            {informacion.data[0].id_tipo_contenido == 1 ? 
            <div>
                <div class="cu_t">
                    <b className="textoTitulo">{informacion.data[0].titulo}</b>
                </div> 

                <div class="cu_c_t">
                    <p className='g_txt'>{informacion.data[0].contenido}</p>
                </div>
            </div>
            : 
            <div class="cu_img">
                <img className="image" src={informacion.data[0].contenido}/>
            </div>
            }
            </div>
            {informacion.data[1].id_tipo_contenido == 1 ? 
                <div>
                    <div class="c_t_tt">
                        <b className="textoTitulo">{informacion.data[1].titulo}</b>
                    </div> 

                    <div class="c_c_tr">
                        <p className='g_txt'>{informacion.data[1].contenido}</p>
                    </div>
                </div>
                : 
                <div class="c_img">
                    <img className="image" src={informacion.data[1].contenido}/>
                </div>       
            }
            {informacion.data[2].id_tipo_contenido == 1 ? 
                <div className='dl'>
                    <div class="c_t_bb">
                        <b className="textoTitulo">{informacion.data[2].titulo}</b>
                    </div> 

                    <div class="c_c_tb">
                        <p className='g_txt'>{informacion.data[2].contenido}</p>
                    </div>
                </div>
                : 
                <div class="c_img_b">
                    <img className="image" src={informacion.data[2].contenido}/>
                </div>
                }
        </div>
        
        :<></>}

        {informacion.data.plantilla == 5 ?  
        <div>
            <div className='dl'>

            {informacion.data[0].id_tipo_contenido == 1 ? 
            <div>
                <div class="c_t_tt">
                    <b className="textoTitulo">{informacion.data[0].titulo}</b>
                </div> 

                <div class="c_c_tl">
                    <p className='g_txt'>{informacion.data[0].contenido}</p>
                </div>
            </div>
            : 
            <div class="c_img">
                <img className="image" src={informacion.data[0].contenido}/>
            </div>
            }
            </div>
            <div className='dr'>
            {informacion.data[1].id_tipo_contenido == 1 ? 
            <div>
                <div class="c_t_tt">
                    <b className="textoTitulo">{informacion.data[1].titulo}</b>
                </div> 

                <div class="c_c_tr_ci">
                    <p className='g_txt'>{informacion.data[1].contenido}</p>
                </div>
            </div>
            : 
            <div class="c_img">
                <img className="image" src={informacion.data[1].contenido}/>
            </div>
            }
            </div>
            {informacion.data[2].id_tipo_contenido == 1 ? 
            <div>
                <div class="ci_t">
                    <b className="textoTitulo">{informacion.data[2].titulo}</b>
                </div> 

                <div class="ci_c_t">
                    <p className='g_txt'>{informacion.data[2].contenido}</p>
                </div>
            </div>
            : 
            <div class="ci_img">
                <img className="image" src={informacion.data[2].contenido}/>
            </div>
            }
        </div>
        
        :<></>}

        {informacion.data.plantilla == 6 ?  
        <div>
            <div>
                <div className='dl_se'>
                {informacion.data[0].id_tipo_contenido == 1 ? 
                <div>
                    <div class="c_t_tt">
                        <b className="textoTitulo">{informacion.data[0].titulo}</b>
                    </div> 

                    <div class="c_c_tl">
                        <p className='g_txt'>{informacion.data[0].contenido}</p>
                    </div>
                </div>
                : 
                <div class="c_img">
                    <img className="image" src={informacion.data[0].contenido}/>
                </div>
                }
                </div>
                {informacion.data[1].id_tipo_contenido == 1 ? 
                <div>
                    <div class="c_t_tt">
                        <b className="textoTitulo">{informacion.data[1].titulo}</b>
                    </div> 

                    <div class="c_c_tr">
                        <p className='g_txt'>{informacion.data[1].contenido}</p>
                    </div>
                </div>
                : 
                <div class="c_img">
                    <img className="image" src={informacion.data[1].contenido}/>
                </div>
                }
            </div>
            <div>          
                {informacion.data[2].id_tipo_contenido == 1 ? 
                <div className='dl'>
                    <div class="c_t_bb">
                        <b className="textoTitulo">{informacion.data[2].titulo}</b>
                    </div> 

                    <div class="c_c_tb">
                        <p className='g_txt'>{informacion.data[2].contenido}</p>
                    </div>
                </div>
                : 
                <div class="c_img_b">
                    <img className="image" src={informacion.data[2].contenido}/>
                </div>
                }
                <div className='dr'>
                {informacion.data[3].id_tipo_contenido == 1 ? 
                <div>
                    <div class="c_t_bb">
                        <b className="textoTitulo">{informacion.data[2].titulo}</b>
                    </div> 

                    <div class="c_c_tb">
                        <p className='g_txt'>{informacion.data[3].contenido}</p>
                    </div>
                </div>
                : 
                <div class="c_img_b">
                    <img className="image" src={informacion.data[3].contenido}/>
                </div>
                }
                </div>
            </div>    
        </div>
        
        :<></>}

        </div>
            </body>
        : <div></div>    
    )


    // return (
    //     informacion
    //     ?   <body>
    //             <div class="wrapper">
    //                 <br></br>
    //                 <div class="Titulo">
    //                     <h5>INFORMACIONES</h5>
    //                 </div>
    //                 {
    //                 informacion.data[0].id_tipo_contenido == 2 ? 
    //                 <div class="TituloCard">
    //                     <b className="textoTitulo">{informacion.data[0].titulo}</b>
    //                 </div> :
    //                 <></>
    //                 }

    //                 {informacion.data[0].id_tipo_contenido == 2 ? 
    //                 <div class="card">
    //                     <p>{informacion.data[0].contenido}</p>

    //                 </div>
    //                 : 
    //                 <div class="cardI">
    //                     <img className="image" src={informacion.data[0].contenido}/>
    //                 </div>
    //                 }
                                
    //                 {
    //                 informacion.data[1].id_tipo_contenido == 1 ? 
    //                 <div class="TituloCard">
    //                     <b className="textoTitulo">{informacion.data[1].titulo}</b>
    //                 </div> :
    //                 <></>
    //                 }

                   
    //                 {informacion.data[1].id_tipo_contenido == 1 ? 
    //                 <div class="card">
    //                     <p>{informacion.data[1].contenido}</p>

    //                 </div>
    //                 : 
    //                 <div class="cardI">
    //                     <img className="image" src={informacion.data[1].contenido}/>
    //                 </div>
    //                 }
            
    //                 <div class="TituloCard">
    //                 <b className="textoTitulo">{informacion.data[2].titulo}</b>
    //                 </div>
    //                 <div class="TituloCard">
    //                     <b className="textoTitulo">{informacion.data[3].titulo}aa</b>
    //                 </div>
            
    //                 <div class="card">
    //                 <br></br>
    //                 <b className="texto">{informacion.data[2].contenido}</b>
    //                 </div>
            
    //                 <div class="card">
    //                     <br></br>
    //                     {informacion.data[3].id_tipo_contenido == 1 ? 
    //                         <p>{informacion.data[3].contenido}</p>
    //                          : <img className="image" src={informacion.data[3].contenido}/>}

    //                 </div>
    //             </div>     
    //         </body>
    //     : <div></div>    
    // )
}
export default Informacion
