import React from 'react'
import './Qrtarifa.css'
import QRTarifa from '../../assets/images/tarifa.PNG'

const Qrtarifa = () => {
  return (
    <div>
      <img className="QRTarifa" alt="TABSA" src= {QRTarifa}></img>
      <div class="TituloQrTarifa">¡Escanea para Obtener Tarifas!</div>
    </div>
  )
}
export default Qrtarifa;
