import './Clima.css'
import React, { useState, useEffect } from 'react'
import axios from 'axios'

const Clima = (props) => {

    const [clima, setClima] = useState(0)

    useEffect( () => {
        const fetchData = async () => {
            const apiKey = 'a1a71f897c25746b862bbe514baf76bc'
            const apiUrl = `https://api.openweathermap.org/data/2.5/weather?&units=metric&lang=${props.lang}&q=${props.ciudad},CL&appid=${apiKey}`
			const result = await axios(apiUrl)
			setClima(result.data)		
		}
		fetchData()
    }, [])

    // useInterval( () => {
	// 	climaCtx.updateCargando(true)
    // }, 60000 * 5)

    const descripcionClima = clima => {
        switch (clima) {
            case 'nubes rotas':
                return 'nublado parcial'
            case  'nubes':
                return 'Nublado'
            default:
                return clima
        }
    }
    return (              
        <div className="ciudad">
            { 
                clima
                ?   (
                    <>
                           <div className="ciudad">
                                <div className="ciudadClima">
                                    <i className={`owi owi-${clima.weather[0].icon} icono`}></i>
                                    <div className="ciudadClimaTemperatura">
                                        <div className="ciudadDetalle">
                                            <h3>{descripcionClima(clima.weather[0].description)} / {clima.weather[0].main}</h3>
                                            <div className="ciudadTemperatura">
                                            {Math.round(clima.main.temp)}°C
                                            </div>
                                            <h2>{clima.name}</h2>
                                        </div>
                                    </div>

                                </div>
                           </div>
                           
                        </>
                    )
                :   (
                        ""
                    )
            }
        </div>
    )
}

export default Clima