import React from 'react'
import './Qr.css'
import QRTarifa from '../../assets/images/tarifa.PNG'
import QrAngostura from '../../assets/images/cliente.png'

const Qr_Angostura = () => {
  return (
    <div>
      <img className="qr-angostura" alt="TABSA" src= {QrAngostura}></img>
      <div class="TituloQr">¡Escanea para Contactarnos!</div>
    </div>
  )
}
export default Qr_Angostura;

