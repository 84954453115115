import React from 'react'
import Clima from './Clima'
import Carousel from 're-carousel'


const ClimaWidget = (props) => {
		

	return (
        <div id="clima">
            <div className="climaContainer">
                <Carousel loop auto duration={4500} axis='x'>
                    
                    <Clima ciudad="Punta Arenas" lang="es"></Clima>
                    <Clima ciudad="Porvenir" lang="es"></Clima>
                    <Clima ciudad="Puerto Williams" lang="es"></Clima>
                    <Clima ciudad="Puerto Natales" lang="es"></Clima>

                </Carousel>
            </div>
        </div>        
	)
}

export default ClimaWidget;