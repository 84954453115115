import React, { useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
} from "react-router-dom";
import moment from 'moment'


import Tarifa from './components/tarifa/Tarifa';
import TarifaWidget from './components/tarifa/TarifaWidget';
import Informacion from './components/Informacion/Informacion';
import Footer from './components/footer/Footer';
import Clima from './components/clima/ClimaWidget';
import ClimaWidget from './components/clima/ClimaWidget';
import Qr_Angostura from './components/qr/Qr';
import Qrtarifa from './components/qrtarifa/Qrtarifa';
import Carousel from 're-carousel';

<meta charset="utf-8"></meta>

const App = (props) => {
    
	return (
        <Router
            basename={"/"}
        >
        {/* <ItinerarioContext.Provider value={ {cargando, updateCargando, nextTrip, updateNextTrip, _ID_EMBARCADERO, setEmbarcadero } }> */}
            <br></br>
            <div className="container">
            {/* <Alerta />
                <Header />
                <br></br>
                <br></br>
                <section className="content">
                    <div className="row flex-space-between">
                        <Mediabox />
                        <Itinerario fecha={hoy.format('DD-MM-Y')} cruces="6"/>

                    </div>
                </section>               
            */}
            <div className='row flex-space-between'>
                <Qr_Angostura/>
                {/* <Logo/> */}
            </div>
            <div>
                <Informacion/>
                <TarifaWidget/>
                <Qrtarifa/>
                {/* <ClimaWidget/> */}
                <Footer/>
            </div>
            </div>
        {/* </ItinerarioContext.Provider> */}
        </Router>
	)
}
export default App;